* {
    box-sizing: border-box;
}

#head {
    position: fixed;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    transition: 0.5s all;
    border-radius: unset !important;
    border-top: unset !important;
    border-right: unset !important;
    border-left: unset !important;
    overflow: initial;
    flex-direction: row;
    z-index: 2;
}

#head .rds-form-item {
    width: auto;
    margin-top: unset !important;
}

.aside_wrapper {
    float: left;
    width: 200px;
    overflow-y: auto;
}

.main-wrapper {
    padding-top: 59px;
    height: 100vh;
}

#icon-list .rds-icon {
    margin: 0 10px 0 0;
}

.sidebar-item_active * {
    font-weight: bolder !important;
}

#main-content {
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 200px;
    width: -webkit-fill-available;
}

.aside_wrapper,
#main-content {
    height: 100%;
}

#main-content_inner {
    max-width: 1200px;
}
