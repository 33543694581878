.breadcrumb {
    float: left;
}

.breadcrumb_value,
.breadcrumb_separator {
    font-weight: 400;
}

.breadcrumb .breadcrumb_value {
    opacity: 0.5;
    transition: 0.4s opacity;
}

.breadcrumb .breadcrumb_value:hover {
    opacity: 0.7;
}

.breadcrumb_separator {
    opacity: 0.15;
}

.breadcrumb:empty {
    width: 180px;
    height: 20px;
    display: inline-block;
    background: #00000020;
}

.rds-btn {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    width: auto;
    min-width: auto;
    max-width: unset;
    float: none;
    display: inline-block;
    display: inline-flex;
    outline: 0;
    align-items: center;
    justify-content: space-around;
    font-weight: 600;
    transition: 0.2s all;
    cursor: pointer;
}

.rds-btn.rds-btn_disabled {
    cursor: not-allowed;
    filter: grayscale(1);
}

.rds-btn * {
    pointer-events: none;
}

.rds-btn,
.rds-btn * {
    font-size: 15px;
}

.rds-btn.rds-transparent {
    box-shadow: unset;
}

.rds-btn.rds-btn_labelled {
    padding: var(--rds-buttonPadding);
}

.rds-btn:not(.rds-btn_labelled) {
    padding: 10px;
}

.rds-btn.rds-btn_labelled .rds-icon {
    margin: 0 9px 0 0;
}

.rds-card {
    transition: 0.2s background-color;
}

.rds-card:not(.rds-overflow_visible):not(.rds-table_content) {
    overflow: hidden;
}

.rds-btn_card-hover {
    opacity: 0;
    visibility: hidden;
    margin: unset !important;
}
.rds-card:hover .rds-btn_card-hover {
    opacity: 1;
    visibility: initial;
}

.rds-carrousel {
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.rds-carrousel-container {
    transition: 0.2s transform;
}
.rds-carrousel .rds-carrousel-handlers {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    pointer-events: none;
}
.rds-carrousel .rds-card.rds-carrousel-handler {
    padding: unset;
    color: white;
    cursor: pointer !important;
    transition: 0.2s transform;
    pointer-events: all;
}
.rds-carrousel
    .rds-card.rds-carrousel-handler:hover:not(.rds-carrousel-handler_disabled) {
    transform: scale(1.1);
}
.rds-carrousel .rds-card.rds-carrousel-handler.rds-carrousel-handler_left {
    margin-left: -2%;
}
.rds-carrousel .rds-card.rds-carrousel-handler.rds-carrousel-handler_right {
    margin-right: -2%;
}
.rds-carrousel .rds-card.rds-carrousel-handler.rds-carrousel-handler_disabled {
    transform: scale(0);
}

.rds-code {
    overflow-x: auto;
}

.rds-code code,
.rds-code code * {
    font-family:
        Roboto Mono,
        monospace !important;
    font-size: 14px;
}

.rds-code code {
    white-space: unset !important;
}

.rds-code .react-syntax-highlighter-line-number {
    opacity: 0.5;
    margin-left: var(--rds-spacingSmall);
    margin-right: var(--rds-spacingSmall);
}

.rds-tag.rds-code_copy {
    right: var(--rds-spacingSmall);
    top: var(--rds-spacingSmall);
}

.rds-collapsible_header {
    cursor: pointer;
}

.rds-collapsible_chevron {
    transition: 0.2s all;
}

.rds-collapsible_content {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
    /* margin-left: -0.5%; */
    overflow: visible;
    transition: 0.2s all;
}

.rds-collapsible_content:not(.rds-collapsible_content__expanded) {
    height: 0;
    padding-top: unset !important;
    padding-bottom: unset !important;
    overflow: hidden;
}

.rds-collapsible_content__expanded {
    height: auto;
}

.rds-files-picker_file {
    position: relative;
    margin-right: 8px;
    display: inline-block;
    overflow: hidden;
}
.rds-files-picker_file-actions-btn {
    visibility: hidden;
    transform: scale(0);
    transition: 0.2s transform;
    position: absolute;
    right: var(--rds-spacingSmall);
    top: var(--rds-spacingSmall);
    z-index: 1;
}
.rds-files-picker_file:hover .rds-files-picker_file-actions-btn {
    visibility: visible;
    transform: scale(1);
}
.rds-files-picker_file-preview,
.rds-files-picker_file-preview-shadow {
    position: relative;
    width: 100px;
    height: 100px;
    -o-object-fit: contain;
       object-fit: contain;
    display: block;
}
.rds-files-picker_file-preview.card {
    padding: var(--rds-spacingSmall);
    width: auto;
    max-width: 150px;
}
.rds-files-picker_file-preview.card * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.rds-files-picker_file-preview-shadow {
    position: absolute;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 0;
    filter: blur(5px);
}

.rds-form-section_inner.rds-grid {
    row-gap: var(--rds-spacingMedium);
    -moz-column-gap: var(--rds-spacingMedium);
         column-gap: var(--rds-spacingMedium);
}

input,
textarea,
.rds-form-item_select {
    float: left;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 12px 18px;
    outline: unset;
}

input[type='file'] {
    background: unset !important;
}

.rds-form-item_select_icon {
    position: absolute;
    top: 50%;
    right: var(--rds-spacingSmall);
    transition: 0.2s transform;
}

.rds-form-select {
    display: inline-block;
    padding: 9px;
}

.rds-form-select:not(.rds-form-select_cards) {
    max-width: -webkit-fill-available;
}

.rds-btn.rds-full-block {
    width: 100%;
}

.rds-form_btn-group {
    margin: 0 var(--rds-spacingSmall) 0 0;
}

@media screen and (max-width: 1400px) {
    .rds-form-select_cards.rds-grid.rds-grid_3-columns {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .rds-form {
        padding-bottom: 50px;
    }

    .rds-form-select_cards.rds-grid.rds-grid_3-columns {
        grid-template-columns: repeat(1, 1fr);
    }
}

.react-datepicker-wrapper {
    width: 100%;
    display: flex;
}

.selected-product_list,
.rds-item-browser_results {
    float: left;
    width: 100%;
}

.rds-item-browser_results {
    position: relative;
}

.rds-item-browser_results article.card {
    padding: 8px 15px;
    cursor: pointer;
    margin: 0 0 8px 0;
}

.rds-item-browser_results .rds-tag {
    margin: unset !important;
    text-align: center;
}

.rds-form-item_checkbox {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

.rds-form-item_checkbox:not(.rds-form-item_disabled) {
    cursor: pointer;
}

.rds-form-item_checkbox-inner {
    display: inline-block;
    height: 20px;
    width: 20px;
}

.rds-form-item_checkbox .rds-form-item_checkbox-icon {
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.2s all;
    opacity: 0;
    padding: 1px;
}

.rds-form-item_checkbox.active .rds-form-item_checkbox-icon {
    opacity: 1;
}

.rds-form-item_icon {
    margin: 0 var(--rds-spacingSmall);
    filter: grayscale(1);
    opacity: 0.5;
    transition: 0.2s all;
}
.rds-form-item_icon.active {
    filter: grayscale(0);
    opacity: 1;
}

.rds-form-item_label {
    transition: 0.2s all;
}

.rds-form {
    max-width: 1300px;
    height: -webkit-fill-available;
    transition:
        0.4s transform,
        0.6s opacity;
    will-change: transform;
    margin-left: auto;
    margin-right: auto;
}

.rds-form_content {
    flex-wrap: wrap;
}

.rds-form_submitting .rds-form-item {
    pointer-events: none;
    opacity: 0.5;
}

.rds-form.unloaded {
    opacity: 0;
    transform: translateX(-20px);
}

.rds-form.loaded {
    opacity: 1;
    transform: translateX(0);
}

.rds-form .rds-card.rds-form-section_card {
    width: 100%;
}

.rds-form .rds-form-section {
    float: left;
    display: inline-flex;
    min-width: 300px;
}

.rds-form .rds-form-section.rds-form-section_small {
    grid-column: span 1;
}

.rds-form .rds-form-section.rds-form-section_medium,
.rds-form-item {
    grid-column: span 2;
}

.rds-form-item.SMALL {
    grid-column: span 1;
}

.rds-form .rds-form-section.rds-form-section_big {
    grid-column: span 3;
}

@media screen and (max-width: 900px) {
    .rds-form-item.SMALL {
        width: 100%;
        margin-right: unset;
    }
}

.rds-form-item input[type='color'],
.rds-form-item input[type='range'] {
    padding: unset;
}

.rds-form-item input[type='color'] {
    width: 35px;
    height: 35px;
    float: left;
    display: block;
    min-width: unset;
    overflow: hidden;
    margin: unset;
}

input[disabled],
textarea[disabled],
.rds-form-item_disabled {
    cursor: not-allowed;
}

.react-datepicker__input-container input {
    border: unset;
    line-height: 1.9;
}

.react-datepicker-popper {
    z-index: 2 !important;
}

.rds-form-item_label-required {
    font-size: 12px;
    color: var(--rds-colorError);
}

.rds-form-item_tooltip:hover .rds-form-item_tooltip-inner {
    visibility: initial;
}

.rds-form-item_tooltip-icon {
    cursor: pointer;
}

.rds-grid {
    display: grid !important;
    row-gap: var(--rds-spacingSmall);
    -moz-column-gap: var(--rds-spacingSmall);
         column-gap: var(--rds-spacingSmall);
}

.rds-grid.rds-table_grid_1-column,
.rds-grid.rds-grid_1-column {
    grid-template-columns: repeat(1, 1fr);
}

.rds-grid.rds-table_grid_2-columns,
.rds-grid.rds-grid_2-columns {
    grid-template-columns: repeat(2, 1fr);
}

.rds-grid.rds-table_grid_3-columns,
.rds-grid.rds-grid_3-columns {
    grid-template-columns: repeat(3, 1fr);
}

.rds-grid.rds-table_grid_4-columns,
.rds-grid.rds-grid_4-columns {
    grid-template-columns: repeat(4, 1fr);
}

.rds-grid.rds-table_grid_5-columns,
.rds-grid.rds-grid_5-columns {
    grid-template-columns: repeat(5, 1fr);
}

.rds-grid.rds-table_grid_6-columns,
.rds-grid.rds-grid_6-columns {
    grid-template-columns: repeat(6, 1fr);
}

.rds-grid .rds-grid_2-columns_item {
    grid-column: span 2;
}

.rds-grid .rds-grid_2-rows_item {
    grid-row: span 2;
}

.rds-grid .rds-grid_3-columns_item {
    grid-column: span 3;
}

.rds-grid .rds-grid_3-rows_item {
    grid-row: span 3;
}

.rds-grid .rds-grid_4-columns_item {
    grid-column: span 4;
}

.rds-grid .rds-grid_4-rows_item {
    grid-row: span 4;
}

.rds-grid .rds-grid_5-columns_item {
    grid-column: span 5;
}

.rds-grid .rds-grid_5-rows_item {
    grid-row: span 5;
}

.rds-grid .rds-grid_6-columns_item {
    grid-column: span 6;
}

.rds-grid .rds-grid_6-rows_item {
    grid-row: span 6;
}

@media screen and (max-width: 900px) {
    .rds-grid.rds-grid_6-columns {
        grid-template-columns: repeat(5, 1fr);
    }
    .rds-grid.rds-grid_5-columns {
        grid-template-columns: repeat(4, 1fr);
    }
    .rds-grid.rds-grid_4-columns {
        grid-template-columns: repeat(3, 1fr);
    }
    .rds-grid.rds-grid_3-columns {
        grid-template-columns: repeat(2, 1fr);
    }
    .rds-grid.rds-grid_2-columns {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .rds-grid.rds-grid_6-columns {
        grid-template-columns: repeat(4, 1fr);
    }
    .rds-grid.rds-grid_5-columns {
        grid-template-columns: repeat(3, 1fr);
    }
    .rds-grid.rds-grid_4-columns {
        grid-template-columns: repeat(2, 1fr);
    }
    .rds-grid.rds-grid_3-columns {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 450px) {
    .rds-grid.rds-grid_6-columns {
        grid-template-columns: repeat(1, 1fr);
    }
    .rds-grid.rds-grid_5-columns {
        grid-template-columns: repeat(1, 1fr);
    }
    .rds-grid.rds-grid_4-columns {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Fonts */
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700;800&display=swap);

* {
    margin: 0;
    font-family:
        'Plus Jakarta Sans',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Open Sans',
        'Helvetica Neue',
        sans-serif;
    line-height: 1.7;
    box-sizing: border-box;
    text-decoration: none;
    /* VARIABLES */
    /* Spacing */
    --rds-spacingLarge: 55px;
    --rds-spacingMedium: 25px;
    --rds-spacingSmall: 10px;
    /* Button */
    --rds-buttonPadding: 5px 20px;
    /* Colors */
    --rds-colorWhite: hsl(0deg 0% 100% / 85%);
    --rds-colorError: hsl(345deg 100% 50% / 1);
    --rds-colorErrorTransparent: hsl(345deg 100% 50% / 10%);
    --rds-colorErrorTransparentHover: hsl(345deg 100% 50% / 20%);
    --rds-colorWarning: hsl(35deg 100% 50% / 1);
    --rds-colorWarningTransparent: hsl(35deg 100% 50% / 10%);
    --rds-colorGreen: hsl(110deg 60% 45% / 1);
    --rds-colorGreenTransparent: hsl(110deg 60% 45% / 10%);
    --rds-colorPurple: hsl(250deg 100% 65% / 1);
    --rds-colorPurpleTransparent: hsl(250deg 100% 65% / 10%);
    --rds-colorGrayDark: hsl(0deg 0% 20%);
    --rds-colorGrayLight: #f6f6f6;
    --rds-colorBlackDark: #0f0f0f;
    --rds-colorBlackLight: #011637;
    --rds-colorBlackLightTransparent: #00002f20;
    --rds-colorDisabledLight: hsl(0deg 0% 95%);
    --rds-colorDisabledDark: hsl(0deg 0% 30%);
    --rds-colorBorderDark: #464646;
    --rds-colorBorderLight: #e6e6e6;
    --rds-colorCardLight: hsl(0deg 0% 100%);
    --rds-colorCardDark: hsl(0deg 0% 9%);
    /* Logo */
    --rds-loadingSpeed: 2s;
    --rds-loadingShadowSize: 30px;
    --rds-isologoBackgroundPosition: 8px 8px;
}

/* This is for borwser support */
[align='center'] {
    text-align: center;
}

/* THEME */
.rounded-border,
.react-datepicker-wrapper input,
::-webkit-scrollbar-thumb {
    border-radius: var(--rds-spacingSmall);
}

.rounded-border_full {
    border-radius: 50px;
}

.rds-nowrap,
.nowrap {
    white-space: nowrap;
}

li {
    margin-bottom: var(--rds-spacingSmall);
}

.rds-loading {
    display: inline-block;
}

.rds-loading .rds-isologo {
    display: inline-block;
    margin: unset;
    animation: isologoAnimation var(--rds-loadingSpeed) infinite;
}

.rds-loading .rds-isologo::after {
    content: '';
    opacity: 0.2;
    background-color: inherit;
    background-image: inherit;
    border-radius: inherit;
    position: absolute;
    width: var(--rds-loadingShadowSize);
    height: var(--rds-loadingShadowSize);
}

.rds-loading,
.rds-isologo::after {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@keyframes isologoAnimation {
    0% {
        transform: rotate(0);
        background-position: 0px 0px;
    }
    50% {
        transform: rotate(180deg);
        background-position: 7px 7px;
    }
    100% {
        transform: rotate(360deg);
        background-position: 18px 18px;
    }
}

.rds-loading .rds-isologo::after {
    animation: isologoShadowAnimation var(--rds-loadingSpeed) infinite;
}

@keyframes isologoShadowAnimation {
    0% {
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) scale(0.5);
    }
    60% {
        opacity: 0.2;
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
    70% {
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) scale(1.15);
    }
    100% {
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) scale(1.15);
    }
}

.rds-loading_wrapper.rds-flex {
    display: inline-flex;
}

.rds-loading_wrapper .rds-loading.rds-absolute {
    position: initial;
    width: auto;
    height: auto;
    margin: 0 5px 0 0;
    transform: unset;
    display: inline-flex;
}

.rds-loading_wrapper .rds-isologo {
    margin: inherit;
}

.rds-logo,
.rds-logo * {
    font-weight: 700;
    line-height: 1.1;
}

.rds-logo span {
    font-size: 1.4rem !important;
    height: 26px;
    line-height: 1;
}

.application-logo {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    display: block;
}

/************************************************************************************************/
/*************************************** IMPORTANT **********************************************/
/************************************************************************************************/
/** Keep these rules at the bottom as they are used by developers to overwrite the ones above  **/
/************************************************************************************************/
/* Around */
.rds-m_around {
    margin: var(--rds-spacingLarge);
}
.rds-m_around__md {
    margin: var(--rds-spacingMedium);
}
.rds-m_around__sm {
    margin: var(--rds-spacingSmall);
}
/* Top */
.rds-m_top {
    margin-top: var(--rds-spacingLarge);
}
.rds-m_top__md {
    margin-top: var(--rds-spacingMedium);
}
.rds-m_top__sm {
    margin-top: var(--rds-spacingSmall);
}
/* Bottom */
.rds-m_bottom {
    margin-bottom: var(--rds-spacingLarge);
}
.rds-m_bottom__md {
    margin-bottom: var(--rds-spacingMedium);
}
.rds-m_bottom__sm {
    margin-bottom: var(--rds-spacingSmall);
}
/* Right */
.rds-m_right {
    margin-right: var(--rds-spacingLarge);
}
.rds-m_right__md {
    margin-right: var(--rds-spacingMedium);
}
.rds-m_right__sm {
    margin-right: var(--rds-spacingSmall);
}
/* Left */
.rds-m_left {
    margin-left: var(--rds-spacingLarge);
}
.rds-m_left__md {
    margin-left: var(--rds-spacingMedium);
}
.rds-m_left__sm {
    margin-left: var(--rds-spacingSmall);
}

/************************************************************************************************/
/*************************************** IMPORTANT **********************************************/
/************************************************************************************************/
/** Keep these rules at the bottom as they are used by developers to overwrite the ones above  **/
/************************************************************************************************/
/* Around */
.rds-p_around {
    padding: var(--rds-spacingLarge);
}
.rds-p_around__md {
    padding: var(--rds-spacingMedium);
}
.rds-p_around__sm {
    padding: var(--rds-spacingSmall);
}
/* Top */
.rds-p_top {
    padding-top: var(--rds-spacingLarge);
}
.rds-p_top__md {
    padding-top: var(--rds-spacingMedium);
}
.rds-p_top__sm {
    padding-top: var(--rds-spacingSmall);
}
/* Bottom */
.rds-p_bottom {
    padding-bottom: var(--rds-spacingLarge);
}
.rds-p_bottom__md {
    padding-bottom: var(--rds-spacingMedium);
}
.rds-p_bottom__sm {
    padding-bottom: var(--rds-spacingSmall);
}
/* Right */
.rds-p_right {
    padding-right: var(--rds-spacingLarge);
}
.rds-p_right__md {
    padding-right: var(--rds-spacingMedium);
}
.rds-p_right__sm {
    padding-right: var(--rds-spacingSmall);
}
/* Left */
.rds-p_left {
    padding-left: var(--rds-spacingLarge);
}
.rds-p_left__md {
    padding-left: var(--rds-spacingMedium);
}
.rds-p_left__sm {
    padding-left: var(--rds-spacingSmall);
}

/* Positioning */
.rds-relative {
    position: relative;
}

.rds-absolute {
    position: absolute;
}

.rds-screen-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.rds-centered {
    text-align: center;
    height: unset;
    width: unset;
    float: none;
    margin: auto;
}

/* Sizing */
.rds-height_fill-available {
    height: -webkit-fill-available;
}

.rds-width_fill-available {
    width: -webkit-fill-available;
}

.rds-height_fit-content {
    height: -moz-fit-content;
    height: fit-content;
}

.rds-width_fit-content {
    width: -moz-fit-content;
    width: fit-content;
}

.rds-height_auto {
    height: auto;
}

.rds-width_auto {
    width: auto;
}

/* Overflowing */
.rds-overflow_auto {
    overflow: auto;
}

.rds-overflow_visible {
    overflow: visible;
}

.rds-overflow_hidden {
    overflow: hidden;
}

.rds-full-block {
    float: left;
    width: 100%;
    display: block;
}

/* Flex */
.rds-flex,
.rds-flex {
    display: flex;
}

.rds-flex.rds-align-center {
    align-items: center;
}

.rds-flex.rds-align-end {
    align-items: flex-end;
}

.rds-flex.rds-align-top {
    align-items: flex-start;
}

.rds-flex.rds-justify-between {
    justify-content: space-between;
}

.rds-flex.rds-justify-center {
    justify-content: center;
}

.rds-flex.rds-justify-left {
    justify-content: left;
}

.rds-flex.rds-justify-evenly {
    justify-content: space-evenly;
}

.rds-flex.rds-justify-end {
    justify-content: flex-end;
}

.rds-flex.rds-direction-column {
    flex-direction: column;
}

.rds-flex.rds-direction-reverse {
    flex-direction: row-reverse;
}

.progress-bar_wrapper {
    float: none;
    margin: auto;
    display: inline-block;
    overflow: hidden;
    max-width: 300px;
    height: 25px;
    padding: 5px;
}

.progress-bar {
    float: left;
    height: 100%;
    transition: 0.2s width;
    background-position: 0% 50%;
    background-size: 400% 400%;
    animation: barProgress 1s ease infinite;
}

@keyframes barProgress {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 50% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.rds-rate_selector-item_interactive {
    cursor: pointer;
    transition: 0.2s transform;
}

.rds-rate_selector-item_interactive:hover {
    transform: scale(1.2);
}

.top-sold-products_record-detail {
    float: left;
    width: 100%;
    margin: 0 0 0 15px;
}

.reports_bars,
.reports_bar-section {
    height: 100%;
}

.reports_bars {
    margin: 0 20px;
}

.reports_bar-section {
    text-align: center;
    margin: 0 8px 0 0;
    width: -moz-fit-content;
    width: fit-content;
    float: left;
    grid-template-rows: 100% auto;
}

.reports_bar-wrapper,
.reports_bar {
    width: 25px;
}

.reports_bar-wrapper {
    height: 100%;
    align-items: flex-end;
    margin: auto;
    padding: 5px;
}

.reports_bar {
    cursor: pointer;
    position: relative;
}

.reports_bar_detail-panel {
    position: absolute;
    left: 100%;
    margin: 0 0 0 var(--rds-spacingSmall);
    padding: 8px 15px;
    z-index: 2;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 0.2s transform;
    pointer-events: none;
    width: -moz-max-content;
    width: max-content;
}

.reports_bar_detail-panel::after {
    content: '';
    position: absolute;
    left: -5px;
    top: 47%;
    background: inherit;
    width: var(--rds-spacingSmall);
    height: var(--rds-spacingSmall);
    transform: rotate(45deg) translateY(-50%);
}

.reports_bar:hover .reports_bar_detail-panel {
    transform: scale(1);
}

.rds-state .rds-icon {
    opacity: 0.5;
}

.rds-table.card {
    padding: 5px 0 0 0;
}

.rds-table.card .rds-table_header {
    padding: var(--rds-spacingSmall) 20px;
}

.rds-table.card .rds-tag {
    margin: unset;
}

.rds-table_img {
    width: 40px;
    height: 40px;
    -o-object-fit: cover;
       object-fit: cover;
}

.rds-table_column-headers {
    width: calc(100% - (var(--rds-spacingMedium) * 2));
}

.rds-table_content.rds-card {
    max-height: 500px;
    overflow-y: auto;
    margin: var(--rds-spacingSmall);
    width: calc(100% - (var(--rds-spacingSmall) * 2));
}

.rds-tag {
    display: inline-flex;
    line-height: 1;
    padding: 3px var(--rds-spacingSmall);
    transition:
        0.4s transform,
        0.4s opacity,
        0.2s background-color,
        0.2s padding;
}

.rds-tag .rds-tag_inner {
    font-weight: 600;
    width: -moz-max-content;
    width: max-content;
    font-size: 12px;
}

.rds-tag.expanded {
    padding: 2px 20px;
}

/* RED */
.rds-tag.RED {
    color: var(--rds-colorError);
    background: var(--rds-colorErrorTransparent);
    border-color: var(--rds-colorErrorTransparent);
}
.rds-tag.RED * {
    color: var(--rds-colorError) !important;
    fill: var(--rds-colorError) !important;
}

/* GREEN */
.rds-tag.GREEN {
    color: var(--rds-colorGreen);
    background: var(--rds-colorGreenTransparent);
    border-color: var(--rds-colorGreenTransparent);
}
.rds-tag.GREEN * {
    color: var(--rds-colorGreen) !important;
}

/* PURPLE */
.rds-tag.PURPLE {
    color: var(--rds-colorPurple);
    background: var(--rds-colorPurpleTransparent);
    border-color: var(--rds-colorPurpleTransparent);
}
.rds-tag.PURPLE * {
    color: var(--rds-colorPurple) !important;
}

/* WARNING */
.rds-tag.WARNING {
    background: var(--rds-colorWarningTransparent);
    border-color: var(--rds-colorWarning);
}
.rds-tag.WARNING * {
    color: var(--rds-colorWarning) !important;
}

.rds-tag.TRANSPARENT {
    background: transparent;
}

[align='center'] .rds-tag {
    margin: var(--rds-spacingSmall) 3px;
}

.rds-tag.disabled {
    background: #00000020 !important;
    opacity: 0.5 !important;
    border-color: #00000020 !important;
}

.rds-tag.disabled,
.rds-tag.disabled * {
    color: unset !important;
    filter: grayscale(100%);
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 *,
h2 *,
h3 *,
h4 *,
h5 *,
h6 * {
    font-weight: 700;
}

p,
button {
    font-weight: 400;
}

a {
    display: inline-block;
}

header.main-header h1,
header.main-header h2,
header.main-header h3,
header.main-header h4,
header.main-header h5,
header.main-header h6 {
    display: inline-block;
}

.main-header.center {
    margin: auto;
    width: 100%;
}

.main-header.center * {
    margin: auto;
}

.rds-text-align_center {
    text-align: center;
}
.rds-text-align_right {
    text-align: right;
}
.rds-text-align_left {
    text-align: left;
}
.rds-text-align_justify {
    text-align: justify;
}

.rds-text-variant_link {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
}

@media screen and (max-width: 600px) {
    body {
        font-size: 12px;
    }
}

.rds-item-browser_input {
    position: relative;
    z-index: 1;
}

.rds-item-browser_input.rds-item-browser_input_active {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}

.rds-card.rds-item-browser_result-item {
    padding: var(--rds-spacingSmall);
}

.rds-card.rds-item-browser_result-item:not(.rds-full-block) {
    width: 48%;
    margin-right: 1%;
}

.rds-item-browser_result-item_img {
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
